<template>
  <v-layout
    wrap
    column
    justify-start
    :class="!bMini ? 'viewLayoutWrapper MiniPadding' : 'viewLayoutWrapper'"
  >
    <!-- #region Start title -->
    <div class="SectionTitle d-flex align-center">
      <v-btn icon fab small @click="goBack()">
        <v-icon style="font-size: 30px; color: black">
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <span> Detalle de orden de compra </span>
      <span>
        <v-chip class="ml-2" text-color="#FFFFFF" :color="getColorStatusOC()"
          >{{ statusName }}
        </v-chip>
      </span>
      <span>
        <v-chip
          class="ml-2"
          text-color="#FFFFFF"
          :color="getColorsOCPaymentStatus()"
          >{{ sPurchaseOrderPaymentStatus }}
        </v-chip>
      </span>
    </div>
    <!-- #endregion End title -->

    <v-tabs
      v-model="iTab"
      background-color="transparent"
      class="content-header-tab-all-global"
    >
      <v-tab
        class="content-header-tab-global"
        v-for="(item, i) in aItemsTab"
        :key="i"
      >
        {{ item.text }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="iTab" class="content-tab-all-global">
      <v-tab-item v-for="(item, i) in aItemsTab" :key="i">
        <KeepAlive>
          <component
            :is="item.component"
            :mdUp="mdUp"
            :smUp="smUp"
            :texts="sectionTxt"
            :bReadonlyMaterials="true"
            @setStatusOC="setStatusOC"
          />
        </KeepAlive>
      </v-tab-item>
    </v-tabs-items>
  </v-layout>
</template>
<script>
export default {
  data: () => ({
    bLoading: false,
    iTab: 0,
    aItemsTab: [
      { text: "General", component: "PurchaseOrder" },
      { text: "Pagos parciales", component: "PartialPayments" },
    ],
    statusName: "",
    sPurchaseOrderPaymentStatus: "",
  }),
  components: {
    // import table sypplier component
    PurchaseOrder: () => import("../../components/core/PurchaseOrder"),
    //import advance purchase component
    PartialPayments: () =>
      import("../../components/core/PartialPayments/content"),
  },
  props: {
    // import texts from admin
    texts: Object,
    mdUp: Boolean,
    smUp: Boolean,
    bMini: Boolean,
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "ProviderDetail",
        params: { sSupplierId: this.$route.params.sIdProvider },
      });
    },
    setStatusOC(oStatusName) {
      this.statusName = oStatusName.statusOrder;
      this.sPurchaseOrderPaymentStatus =
        oStatusName.sPurchaseOrderPaymentStatus;
    },
    getColorsOCPaymentStatus() {
      switch (this.sPurchaseOrderPaymentStatus) {
        case "Pagado":
          return "#97e6b6";
        case "No pagado":
          return "#ff7d7d";
        case "Pagado parcial":
          return "#ffce00";
        default:
          break;
      }
    },
    getColorStatusOC() {
      switch (this.statusName) {
        case "Enviada":
          return "#79c6ff";

        case "Borrador":
          return "#7e7e7e";

        case "Aprobada":
          return "#97e6b6";
        case "Recibida":
          return "#97e6b6";

        case "Correcciones":
          return "#ff7d7d";

        case "Pagada":
          return "#ffce00";

        case "Cancelada":
          return "#ff7d7d";

        default:
          break;
      }
    },
  },
  computed: {
    sSupplier() {
      return this.$store.state.supplierSelected.sFullName;
    },
    // section texts
    sectionTxt() {
      return this.texts.providers;
    },
  },
};
</script>
